import React from 'react';
import { Helmet } from 'react-helmet';
import { Metadata } from 'types/content/common';

const DocumentHead = ({ content }: { content: Metadata }) => {
  const { title, description } = content;
  const renderNortonVerification = () => {
    return process.env.GATSBY_NORTON_SITE_VERIFICATION ? (
      <meta
        name="norton-safeweb-site-verification"
        content={process.env.GATSBY_NORTON_SITE_VERIFICATION}
      />
    ) : null;
  };

  return (
    <Helmet htmlAttributes={{ lang: 'en' }} title={title}>
      <meta name="description" content={description} />
      {renderNortonVerification()}
    </Helmet>
  );
};

export default DocumentHead;
